<template>
  <div>
    <v-container fluid>
      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-row class=" d-flex text-center">
            <h1 class="titulo ml-4 text-center"> Performance </h1>
          <v-card  class=" select-card mt-3 mx-auto d-flex">
            <div class="select-card-content d-flex">

                <div class="select-date-title ml-4 mt-3 mb-3">
                  Relatório
                </div>

                <div class="select-date">
                  <!-- Voltar mês -->
                  <v-icon @click="voltar(mes)" class="select-date-back"> arrow_back_ios </v-icon>

                  <div class="select-date-text mt-3 mb-3"> 
                        <v-menu open-on-hover offset-y >
                          <template v-slot:activator="{ on, attrs }">
                            <label style="background: white; color: #757575" v-bind="attrs" v-on="on" > {{ mes.text }} </label>
                          </template>

                          <v-list>
                            <v-list-item
                              v-for="(item, index) in optionSelect"
                              :key="index"
                            >
                              <!-- <v-list-item-title> {{ item }}</v-list-item-title> -->
                              <v-list-item-title @click="filtroDate('atual')" class="text-center"> Atual </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                  </div>
                  <!-- Avançar mês -->
                  <v-icon class="select-date-next" @click="proximo(mes)">  arrow_forward_ios  </v-icon>
                  <v-progress-circular  v-if="simpleLoader" class="loader" style="position: relative; top: 8px" indeterminate color="primary" />
                </div>
            </div>

            <!--  Tabs -->
            <v-spacer></v-spacer>


            <v-form style="width: 100%" >
                <tabDespesasReceitas v-model="filtros" />
            </v-form>

          </v-card>
        </v-row>
      </v-skeleton-loader>
    </v-container>
    <!-- <ModalCadastro/>
    <ModalExcluir/> -->
  </div>
</template>

<script>
import Events from '@/core/service/events'
import { mapGetters, mapActions } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'
import moment from 'moment'
moment.locale('pt-br')
const namespaceStore = 'organizzeDespesaReceita'

  export default {
    name: 'DespesasReceitas',
    components: {
      tabDespesasReceitas: () => import('./components/despesasReceitas.vue')
    },
    computed: {
      ...mapGetters(namespaceStore, ['result'])
    },
    data: () => ({
      filtros: null,
      tab: 'tab-0',
      skLoader: false, // loader da pagina geral
      simpleLoader: false, // simples loader
      categoryGroupsEnum: null,
      mes: {},
      year: 0,
      week: 0,
      optionSelect: [ 'atual' ]
    }),
    mounted () {
      this.mes = { mes: moment().month(),
        text: moment().format('MMMM [de] YYYY'),
        type: 'day',
        year: moment().year(),
        week: moment().week()
      }
    },
    watch: {
      'mes' () {
        this.formatFiltro(true)
        // this.buscarDados(true)
      },
      'filtros' () {
        this.formatFiltro()
        // this.buscarDados()
      }
    },
    methods: {
      ...mapActions(namespaceStore, ['getDados']),
      openModalCadastro (data) { Events.$emit('modalCadastro::open', data) },
      editModalCadastro (data) { Events.$emit('modalCadastro::edit', data) },
      openModalExcluir (data) { Events.$emit('modalExcluir::open', data) },
      month (item, increment = false) {
        let ano = moment().year()
        let mes = increment ? item.mes + 1 : item.mes - 1
        if (mes > 11) {
          this.year = increment ? this.year + 1 : this.year - 1
          mes =  increment ? 0 : 11
        }

        if (mes < 0) {
          this.year = this.year - 1
          mes = 11
        }

        this.mes = Object.assign(this.mes, {
          mes: mes,
          text: moment().month(mes).format(`MMMM [de] ${ano + this.year}`),
          date: moment().month(mes).year(ano + this.year).format('YYYY-MM-DD'),
          year: ano + this.year
        })
      },

      // se por acaso mudar o filtro principal.
      filterWeek (item, increment = false) {
        let ano = moment().year()
        this.week = increment ? item.week + 1 : item.week - 1
        
        // let final = moment().week(filtro.week).month(filtro.mes).year(filtro.year).endOf('week').format('DD MMM')
        // let end = moment().week(filtro.week).month(filtro.mes).year(filtro.year).startOf('week').format(`DD MMM [à ${final}]`)
        this.mes = Object.assign(this.mes, {
          week: this.week,
          text: moment().weeks(this.week).month(item.mes).format(`MMMM [de] ${ano + this.year}`),
          date: moment().weeks(this.week).month(this.month).year(ano + this.year).format('YYYY-MM-DD'),
          year: ano + this.year
        })
      },
      buscarDados (activeLoader = false) {
        const vm = this
        const args = Object.assign({}, this.mes)
        args.typeFilter = vm.filtros === null ? 'dt_emission' : vm.filtros.table
        args.pendentes = vm.filtros === null ? true : vm.filtros.pendentes
        args.type = vm.filtros === null ? 'month' : vm.filtros.data
        this.mes.type = args.type

        if (activeLoader) {
          vm.simpleLoader = true
        }
        this.getDados({ _addLFilter: args }).then(() => {
        }).catch(e => {
          errorSnackbar(e.error)
        }).finally(() => {
          vm.simpleLoader = false
          // evento para loader do component
          Events.$emit('loader:component', false)
        })
        
      },
      proximo (item) {
        this.month(item, true)
        // if (this.filtros === null || this.filtros.data === 'month') this.month(item, true) // true incrementa
        // if (this.filtros && this.filtros.data === 'week') this.filterWeek(item, true) // true incrementa
        this.buscarDados(true)
      },
      voltar (item) {
        this.month(item, false)
        // if (this.filtros === null || this.filtros.data === 'month') this.month(item, false) // false soma - 1
        // if (this.filtros && this.filtros.data === 'week') this.filterWeek(item, false) // true incrementa
        this.buscarDados(true)
      },
      filtroDate () {
        this.year = 0
        this.mes = Object.assign(this.mes, {
          mes: moment().month(),
          text: moment().format('MMMM [de] YYYY'),
          date: moment().format('YYYY-MM-DD'),
          year: moment().year()
        })
        this.formatFiltro(true)
      },
      formatFiltro (loaderPage = false) {
        this.buscarDados(loaderPage)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
  .select-card {
    flex-wrap: wrap;
    min-width: 60% !important;
    max-width: 60% !important;
    @media(max-width: 992px ) {
      min-width: 100% !important;
      max-width: 100% !important;
    }
    &-content{
      width: 100%;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      flex-direction: row;
      border-bottom: 1px solid grey;
    }
  }
  .select-date {
    width: 100%;
    display: flex;
    flex-flow: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size:18px;
    &-title {
      color: #1cb4ff;
      font-size: 18px;
    }
    &-back,
    &-next {
      padding: 0 10px;
      cursor: pointer;
    }
  }

  .tabs-content {
    border-bottom: 1px solid grey;
    display: block;
    width: 100%;
  }

</style>